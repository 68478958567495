<template>
    <el-dialog :title="title" :visible="visible" :close-on-click-modal="false" width="1100px" @close="close">
        <el-form ref="editForm" :model="form" :rules="rules" :inline="true" label-position="right" label-width="100px"
            class="editForm">
            <el-form-item prop="InventoryCode" label="盘点单号">
                <el-input disabled v-model="form.InventoryCode"></el-input>
            </el-form-item>
            <el-form-item prop="WID" label="仓库">
                <el-select v-model="form.WID" placeholder="请选择">
                    <el-option v-for="(option, optionIndex) in (form.InitData || {})
                        .WarehouseList || []" :key="optionIndex" :label="option.label" :value="option.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="Note" label="备注">
                <el-input type="textarea" :rows="2" v-model="form.Note" :style="{ width: '498px' }"></el-input>
            </el-form-item>
        </el-form>
        <div v-if="type == 'add' || type == 'edit' || type == 'check'" class="operation">
            <el-button type="primary" @click="selectProduct">选择商品</el-button>
            <el-button type="primary" @click="deleteProduct">删除行</el-button>
        </div>
        <div></div>
        <div></div>
        <div>
            <grid-table ref="gridTable" :tableConfig="tableConfig" :showPagination="false"
                @selectionChange="selectionChange">
            </grid-table>
        </div>
        <product-selector ref="productSelector" v-if="productSelectorDialogVisible"
            @setProduct="setProduct"></product-selector>
        <div slot="footer" class="dialog-footer">
            <el-button v-if="type != 'detail'" type="primary" @click="save">
                {{ type == "add" || type == "edit" ? "保存" : "审核" }}
            </el-button>
            <el-button @click="close">关闭</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { baseURL, successCode } from "@/config";
import { submit } from "@/api/base";
import ProductSelector from "@/views/product/base/components/ProductSelector.vue";

export default {
    name: "InventoryEdit",
    components: {
        ProductSelector,
    },
    data() {
        return {
            visible: false,
            type: "",
            title: "",
            form: {},
            defaultColumns: [
                {
                    label: "商品货号",
                    prop: "ItemNo",
                    type: "text",
                    width: "130px",
                },
                {
                    label: "商品名称",
                    prop: "PName",
                    type: "text",
                    width: "130px",
                },
                {
                    label: "规格名称",
                    prop: "SpecName",
                    type: "text",
                    width: "130px",
                },
                {
                    label: "条形码",
                    prop: "Barcode",
                    type: "text",
                    width: "130px",
                },
                {
                    label: "盘点实存",
                    prop: "NewStock",
                    type: "input",
                    width: "130px",
                },
                {
                    label: "现有库存",
                    prop: "Stock",
                    type: "text",
                    width: "130px",
                },
            ],
            checkedColumns: [
                {
                    label: "商品货号",
                    prop: "ItemNo",
                    type: "text",
                    width: "130px",
                },
                {
                    label: "商品名称",
                    prop: "PName",
                    type: "text",
                    width: "130px",
                },
                {
                    label: "规格名称",
                    prop: "SpecName",
                    type: "text",
                    width: "130px",
                },
                {
                    label: "条形码",
                    prop: "Barcode",
                    type: "text",
                    width: "130px",
                },
                {
                    label: "盘前库存",
                    prop: "OldStock",
                    type: "text",
                    width: "130px",
                },
                {
                    label: "盘后实存",
                    prop: "NewStock",
                    type: "text",
                    width: "130px",
                },
                {
                    label: "库存差异",
                    prop: "AddStockStr",
                    type: "text",
                    width: "130px",
                },
                {
                    label: "成本差异",
                    prop: "AddCostStr",
                    type: "html",
                    width: "130px",
                },
            ],
            rules: {
                InventoryCode: [
                    { required: true, trigger: "blur", message: "不可为空" },
                ],
                WID: [{ required: true, trigger: "blur", message: "请选择" }],
            },
            tableConfig: {
                columns: [],
            },
            selectRows: [],
            uploadUrl:
                baseURL + "api/product/excelSelector?IOType=1",
            productSelectorDialogVisible: false,
        };
    },
    methods: {
        init(type, inventoryID) {
            this.visible = true;
            this.type = type;
            this.title =
                type == "add"
                    ? "新增"
                    : type == "edit"
                        ? "编辑"
                        : type == "check"
                            ? "审核"
                            : "详情";
            this.$nextTick(() => {
                this.fetchData(inventoryID);
            });
        },
        async fetchData(inventoryID) {
            const { data } = await submit("/api/wms/inventory/getInfo", {
                InventoryID: inventoryID,
            });
            this.form = { ...data };
            if (this.form.WID <= 0) {
                this.form.WID = null;
            }
            if (this.type == "detail") {
                if (this.form.CheckFlag == 1) {
                    this.tableConfig.columns = this.checkedColumns;
                } else {
                    this.tableConfig.columns = this.defaultColumns;
                }
            } else {
                this.tableConfig.columns = this.defaultColumns;
            }
            this.tableConfig.columns.forEach((column) => {
                if (column.prop == "NewStock") {
                    column.type = this.type == "detail" ? "text" : "input";
                }
            });
            if (inventoryID > 0) {
                var details = JSON.parse(this.form.DetailJson);
                details.forEach((item) => {
                    item.AddCostStr = item.Cost + "</br>" + item.AddCostStr;
                });
                this.$refs.gridTable.setData(details);
            }
        },
        selectionChange(selection) {
            this.selectRows = selection;
        },
        selectProduct() {
            if (!this.form.WID || this.form.WID == 0) {
                this.$baseMessage("请先选择仓库", "error");
                return;
            }
            this.productSelectorDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.productSelector.init();
            });
        },
        async setProduct(rows) {
            const datas = [...this.$refs.gridTable.tableData];
            const stockSpecID = [];
            rows.forEach((row) => {
                stockSpecID.push(row.SpecID);
                if (!datas.find((data) => data.SpecID == row.SpecID)) {
                    let data = { ...row };
                    data.NewStock = 1;
                    datas.push(data);
                }
            });
            const { data } = await submit("/api/wms/stockQuery/getStock", { "WID": this.form.WID, "SpecIDs": stockSpecID.join() });

            this.$nextTick(() => {
                let stockData = [];
                datas.forEach((row) => {
                    data.forEach((subRow) => {
                        var stockItem = { ...row };
                        if (subRow.SpecID == row.SpecID) {
                            stockItem.Stock = subRow.Stock + "(可用：" + subRow.LeftStock + ")";
                            stockData.push(stockItem);
                        }
                    });

                });
                this.$refs.gridTable.setData(stockData);
            });
        },
        deleteProduct() {
            if (this.selectRows.length == 0) {
                this.$baseMessage("请选择行", "error");
                return;
            }
            this.$baseConfirm("确定选中行要删除吗？", null, () => {
                let datas = [...this.$refs.gridTable.tableData];
                this.selectRows.forEach((row) => {
                    datas.forEach((data, index) => {
                        if (data.SpecID == row.SpecID) {
                            datas.splice(index, 1);
                        }
                    });
                });
                this.$refs.gridTable.setData(datas);
            });
        },
        save() {
            let productDatas = [...this.$refs.gridTable.tableData];
            if (productDatas.length == 0) {
                this.$baseMessage("商品不可为空", "error");
                return;
            }
            this.$refs.editForm.validate(async (valid) => {
                if (valid) {
                    let param = { ...this.form };
                    param.DetailJson = JSON.stringify(productDatas);
                    await submit(
                        this.type == "check"
                            ? "/api/wms/inventory/check"
                            : "/api/wms/inventory/edit",
                        param
                    );
                    this.$baseMessage(
                        (this.type == "check" ? "审核" : "保存") + "成功",
                        "success"
                    );
                    this.$emit("handleQuery");
                    this.close();
                } else {
                    return false;
                }
            });
        },
        close() {
            this.$refs.editForm.resetFields();
            this.$refs.gridTable.tableData = [];
            this.visible = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.editForm {
    border: 1px solid #ebeef5;
    padding-top: 10px;
    margin-bottom: 10px;
}

.operation {
    border: 1px solid #ebeef5;
    padding: 5px;
    margin-bottom: 10px;

    >div {
        display: inline-block;
        margin: 0 10px;
    }
}
</style>
